import React from 'react';
import './Contact.css';

function Contact() {
  return (
    <div className="contact">
      <h1>Contact Us</h1>
      <p>Email: info@intermmusic.com</p>
      <p>Phone: +1 (336) 693-6001</p>
    </div>
  );
}

export default Contact;