import React from 'react';
import ReactPlayer from 'react-player';
import './Home.css';

function Home() {
  return (
    <div className="home">
      <section className="hero">
        <h1>Welcome to Intermmusic</h1>
        <p>Your gateway to the best independent music!</p>
      </section>

      <section className="videos-grid">
        <div className="video-item">
          <ReactPlayer url="https://www.youtube.com/watch?v=u4hMp9Ic-po" />
        </div>
        <div className="video-item">
          <ReactPlayer url="https://www.youtube.com/watch?v=-dhBx4o9F6w" />
        </div>
        <div className="video-item">
          <ReactPlayer url="https://www.youtube.com/watch?v=IAeD3ld7T9U" />
        </div>
        <div className="video-item">
          <ReactPlayer url="https://www.youtube.com/watch?v=jfqsuHZf5ms" />
        </div>
      </section>
    </div>
  );
}

export default Home;