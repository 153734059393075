import React from 'react';
import './About.css';

function About() {
  return (
    <div className="about">
      <h1>About Us</h1>
      <p>
        Intermmusic is a forward-thinking independent music label that champions diverse and groundbreaking artists. We are committed to bringing the freshest sounds to the global stage. With a focus on quality over quantity, our team works tirelessly to ensure our artists receive the support and attention they deserve.
      </p>
    </div>
  );
}

export default About;